import { useTranslation } from '@src/lib/i18n'
import { Flex, PaymentError, SubTitle, Text, Title } from '@welend/apple-shopping-cart-ui-library'
import Error, { ErrorProps as NextErrorProps } from 'next/error'
import React from 'react'

const CustomError = ({ statusCode }: PageProps): JSX.Element => {
  const { t } = useTranslation()
  const contentObject = {
    default: {
      subTitle: t('error:500'),
      description: t('error:500_description')
    },
    notFound: {
      subTitle: t('error:404'),
      description: t('error:404_description')
    },
    unauthorized: {
      subTitle: t('error:401'),
      description: t('error:401_description')
    }
  }
  let content
  switch (statusCode) {
    case 404:
      content = contentObject.notFound
      break
    case 401:
      content = contentObject.unauthorized
      break
    default:
      content = contentObject.default
      break
  }

  return (
    <>
      <Flex mt="24" desktopMT="42" ml="6" mr="6" justifyContent="center" alignItems="center">
        <SubTitle align="center" fontWeight="bold" typeface="large" desktopCenter>
          <PaymentError width="64px" height="64px" />
        </SubTitle>
      </Flex>

      <Flex mt="4" ml="6" mr="6" justifyContent="center" alignItems="center">
        <Title align="center" fontWeight="bold" typeface="large" desktopCenter>
          {content?.subTitle && <SubTitle>{content?.subTitle}</SubTitle>}
        </Title>
      </Flex>

      <Flex mt="4" mb="24" desktopMB="42" ml="6" mr="6" justifyContent="center" alignItems="center">
        <Text align="center" typeface="large" desktopCenter>
          {content?.description && (
            <Text mt={8} dangerouslySetInnerHTML={{ __html: content?.description }} />
          )}
        </Text>
      </Flex>
    </>
  )
}

CustomError.getInitialProps = async (props): Promise<CustomErrorProps> => {
  const { res, err } = props
  // @ts-ignore
  const errorInitialProps: ErrorProps = await Error.getInitialProps({
    res,
    err
  })
  errorInitialProps.isSSRReadyToRender = true
  if (res) {
    if (err) {
      return { ...err, ...errorInitialProps, statusCode: res.statusCode }
    }
  }

  return {
    err,
    ...errorInitialProps
  }
}

declare type PageProps = CustomErrorProps

export declare type CustomErrorProps = {
  err: Error
  statusCode: number
  isSSRReadyToRender: boolean
  children?: React.ReactElement
}

export declare type ErrorProps = {
  isSSRReadyToRender: boolean
} & NextErrorProps

export default CustomError
